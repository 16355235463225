import React from "react";

export default function Hours() {
  return (
    <div className="box">
      <hr />
      <h2 className="intro-text text-center">
        <strong>Hours</strong>
      </h2>
      <hr />
      <div>
        <strong>Tuesday-Friday:</strong> 2:00 to 9:00 PM<br />
        <strong>Saturday:</strong> 10:00 AM to 2:00 PM<br />
        <strong>Closed Sundays and Mondays</strong>
      </div>
    </div>
  )
}